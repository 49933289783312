import React from 'react';

export default function Subscribe() {
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
            <h2 className="text-white mb-5">
              Join the monthly newsletter and receive a free copy of Rites of
              the Renouncer.
            </h2>
            <p className="text-white 25">
              {/* leave here in case I tagline later */}
            </p>

            <form
              className="form-inline d-flex"
              action="https://dl.bookfunnel.com/vqcxkmd8q9"
              method="post"
            >
              <button type="submit" className="btn btn-primary mx-auto">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
