import React from 'react';
// project components
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';
// img assets
import ipad from '../assets/images/ipad.png';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import bgMaster from '../assets/images/bg-masthead.jpg';
import amazonButton from '../../src/assets/img/amazon-button.png';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center"></div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">
              Author of the <i>Vaskania Prime</i> series
            </h2>
            <p className="text-white-50">
              Benjamin Kamphaus is a keytarist and semicolon apologist living in
              the Front Range of Colorado. He has collected and discarded many
              exotic careers, among them: archaeologist, Earth scientist, and
              space industry software engineer. He produces electronic
              music—including soundtracks to accompany the Vaskania Prime
              books—as <a href="http://patternshiftsynth.com">PatternShift</a>.
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid" alt="" width="700" />
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={bgMaster} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-justify">
              <h4>Vaskania Prime</h4>
              <p className="text-black-50 mb-0">
                Vaskania Prime is one of many exoplanets that was targeted for
                colonization from Earth in the late 23rd century. To reach other
                habitable systems, humans designed large-scale, self-replicating
                robotic fleets. Modeled on Von Neumann probes, the ships of the
                fleet were built with minimal resources in the inner Sol system.
                They mined raw materials, hydrocarbons, water, and other
                resources from asteroids and Kuiper belt objects as they
                traveled through the outer system on the way to interstellar
                space. These fleets were operated by distributed AI systems, and
                were capable of self-repair and complex planning. They carried a
                seed vault and multiple copies of all information networks from
                Earth.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-justify">
                  <h4 className="text-white">Rites of the Renouncer</h4>
                  <p className="mb-1 text-white-50 text-justify">
                    Three decades ago, Viktor Goto left his home planet,
                    Vaskania Prime, for remote orbit on Oneiri Station. With AI
                    assistance he altered his body chemistry, using a mix of
                    synthetic hormones and neurotransmitters. He spent his time
                    training, learning to extend his REM sleep by several hours
                    and project new powers into the Dream World. Now he’s back
                    on Vaskania Prime to dream again and find out what happened
                    to his childhood friend, Owen.
                    <hr className="d-none d-lg-block mb-1 ml-0 p-1" />
                    ⭑⭑⭑⭑⭑ A fantastic book, fulfilling that other-worldly
                    wanderlust we all harbor at our cores. This world begs to be
                    explored and to be revisited over and over.
                    <br />
                    <a href="https://www.amazon.com/Rites-Renouncer-Vaskania-Benjamin-Kamphaus/dp/1797007408/">
                      <img
                        className="mt-2 rounded float-left"
                        alt="Find on Amazon"
                        src={amazonButton}
                        width="120"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-justify">
                  <h4 className="text-white">Wake of the Wanderer</h4>
                  <p className="mb-0 text-white-50">
                    The Renouncers of Oneiri Station have dedicated their lives
                    to exploring the Dream World. On a routine excursion, two
                    Renouncers discover a clue left behind by the founder of
                    their order—one that promises to upend their understanding
                    of the planet’s history and the Dream World’s origins. As
                    they race to unravel the Dream World’s secrets, the ancient
                    powers that have been waiting inside it are beginning to
                    stir.
                    <hr className="d-none d-lg-block mb-1 mr-0 p-1" />
                    ⭑⭑⭑⭑⭑ ... considered and intelligent, with faultless science
                    ...
                    <br />
                    ⭑⭑⭑⭑⭑ ... an intense science fiction/thriller mystery that
                    never lets up.
                    <br />
                    <a href="https://www.amazon.com/Wanderer-Vaskania-Prime-Benjamin-Kamphaus-ebook/dp/B085SYDGYT/">
                      <img
                        className="mt-2 rounded float-right"
                        alt="Find on Amazon"
                        src={amazonButton}
                        width="120"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
